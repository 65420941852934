const CUSTOM_SEO_SETTINGS_KEYS = {
  ACCOUNT_SETTINGS_PAGE: 'SeoCmsClientPanelMyAccount',
  ADDONS_LIST_PAGE: 'SeoCmsAddonsSide',
  ADDON_PAGE: 'SeoCmsAddonsSide',
  LOGIN_PAGE: 'SeoCmsClientPanelLogin',
  REGISTER_PAGE: 'SeoCmsClientPanelRegister',
  DIET_PAGE: 'SeoCmsDietSide',
  DISH_PAGE: 'SeoCmsDishSide',
  DIETS_LIST_PAGE: 'SeoCmsDietSide',
  HOME_PAGE: 'SeoCmsMainSite',
  MENU_PAGE: 'SeoCmsMenuSubpage',
  ORDERS_PAGE: 'SeoCmsClientPanelMyAccount',
  ZONE_PAGE: 'SeoCmsZonesSubpage',
  ZONE_LIST_PAGE: 'SeoCmsZonesSubpage',
  BLOG: 'Blog',
} as const;

export default CUSTOM_SEO_SETTINGS_KEYS;
