import Image from '@components/elements/Image/Image';

import DishImageWrapper from './DishImageWrapper';

const DishImage = ({
  backgroundEnabled = false,
  backgroundImageUrl = '',
  imageUrl = '',
  placeholderSrc = null,
  showImage = false,
}) => {
  if (!showImage) {
    return null;
  }

  const isBackgroundExist = backgroundEnabled && backgroundImageUrl;
  const mainDivImageUrl = isBackgroundExist ? backgroundImageUrl : imageUrl;

  return (
    <DishImageWrapper
      className={
        isBackgroundExist ? 'product-tile__background' : 'product-tile__image'
      }
    >
      <Image loading="lazy" layout="fill" src={mainDivImageUrl} />
      {isBackgroundExist && (
        <DishImageWrapper.Inner className="product-tile__image-inner">
          <Image
            layout="fill"
            loading="lazy"
            src={imageUrl}
            placeholderSrc={placeholderSrc}
          />
        </DishImageWrapper.Inner>
      )}
    </DishImageWrapper>
  );
};

export default DishImage;
