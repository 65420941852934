import Head from 'next/head';

import { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import {
  selectBranding,
  selectModuleSeoCms,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { getImageUrlFromResources } from '@services/Api.service';
import { isClient } from '@utils/helpers';

const SeoCms = ({ pageName = '', seoProps = {}, customSeoSettingsKey }) => {
  const { name, favicon } = useAppConfigSelector(selectBranding);
  const seoCms = useAppConfigSelector(selectModuleSeoCms);

  const seo = merge(
    cloneDeep(seoCms[customSeoSettingsKey]),
    cloneDeep(seoProps)
  );

  return (
    <Head>
      <meta charSet="utf-8" />

      <title>
        {seo?.meta?.title
          ? `${seo.meta.title} - ${name}`
          : `${
              seo?.marketingTitle
                ? seo?.marketingTitle
                : `${pageName} - ${name}`
            }`}
      </title>

      {/* Google / Search Engine Tags */}
      {!(seo?.indexing ?? false) && (
        <meta name="robots" content="noindex, nofollow, nosnippet" />
      )}
      {seo?.marketingTitle && (
        <meta itemProp="name" content={seo.marketingTitle} />
      )}
      {seo?.image && (
        <Fragment>
          <meta
            itemProp="image"
            content={getImageUrlFromResources(seo.image, seo['@resources'])}
          />
          <link
            rel="image_src"
            href={getImageUrlFromResources(seo.image, seo['@resources'])}
          />
        </Fragment>
      )}
      {seo?.meta?.description ? (
        <meta name="description" content={seo.meta.description} />
      ) : (
        seo?.metaDescription && (
          <meta name="description" content={seo.metaDescription} />
        )
      )}

      {seo?.meta?.description ? (
        <meta itemProp="description" content={seo.meta.description} />
      ) : (
        seo?.metaDescription && (
          <meta itemProp="description" content={seo.metaDescription} />
        )
      )}

      {/* Facebook Meta Tags */}
      {isClient && <meta property="og:url" content={window.location.href} />}
      <meta property="og:type" content="website" />
      {seo?.openGraphTitle && (
        <meta property="og:title" content={seo.openGraphTitle} />
      )}
      {seo?.openGraphDescription && (
        <meta property="og:description" content={seo.openGraphDescription} />
      )}
      {seo?.image && (
        <meta
          property="og:image"
          content={getImageUrlFromResources(seo.image, seo['@resources'])}
        />
      )}

      {/* Twitter Meta Tags */}
      {isClient && (
        <meta property="twitter:url" content={window.location.href} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {seo?.openGraphTitle && (
        <meta name="twitter:title" content={seo.openGraphTitle} />
      )}
      {seo?.openGraphDescription && (
        <meta name="twitter:description" content={seo.openGraphDescription} />
      )}
      {seo?.image && (
        <meta name="twitter:image" content={seo['@resources'][seo.image]} />
      )}

      {favicon && <link rel="icon" type="image/png" href={favicon} />}
    </Head>
  );
};

SeoCms.displayName = 'SeoCms';

export default SeoCms;
