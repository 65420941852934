import { styled } from 'twin.macro';

const DishImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 265px;
  img {
    object-fit: cover;
  }
`;

DishImageWrapper.Inner = styled.div`
  > span {
    margin: 25px 25px 25px 34px !important;
  }

  img {
    object-fit: contain;
  }
`;

export default DishImageWrapper;
